// Constantes
import { ADDONS } from '@/constants'
import {
  ORDER_STATUS,
  AVERAGE_SHIPPING_TIMES,
  ORDER_TYPES,
  DEFAULT_REASON_REJECTION
} from '../../../../constants'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonMixin from '@/mixins/addonsMixin'
// Services
import { updateOrderById } from '../../../../services/order'
// Filters
import { formatDate } from '@/filters'
// Utils
import { get, isNil } from 'lodash'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'TakeAwayOrderChangeStatus',
  mixins: [uiMixin, addonMixin],
  props: {
    order: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentStatus: this.order.status,
      currentShippingTime: 0,
      processingRequest: false,
      reasonRejection: null,
      reasonRejectionForm: false,
      shippingTimes: AVERAGE_SHIPPING_TIMES,
      // Secuencia de los posibles cambios de estado
      statusActions: {
        // Pendiente --> Aceptado o rechazado
        [ORDER_STATUS.pending.value]: {
          accept: {
            label: ORDER_STATUS.accepted.label,
            fnAction: this.handleChangeStatus.bind(this, ORDER_STATUS.accepted.value)
          },
          cancel: {
            label: ORDER_STATUS.rejected.label,
            fnAction: this.handleRejectForm.bind(this, ORDER_STATUS.rejected.value)
          }
        },
        // Aceptado --> En proceso o cancelado
        [ORDER_STATUS.accepted.value]: {
          accept: {
            label: ORDER_STATUS.inProcess.label,
            fnAction: this.handleChangeStatus.bind(this, ORDER_STATUS.inProcess.value)
          },
          cancel: {
            label: ORDER_STATUS.canceled.label,
            fnAction: this.handleRejectForm.bind(this, ORDER_STATUS.canceled.value)
          }
        },
        // En proceso --> Entregado o cancelado (pedido para recoger)
        [`${ORDER_STATUS.inProcess.value}_ta`]: {
          accept: {
            label: ORDER_STATUS.delivered.label,
            fnAction: this.handleChangeStatus.bind(this, ORDER_STATUS.delivered.value)
          },
          cancel: {
            label: ORDER_STATUS.canceled.label,
            fnAction: this.handleRejectForm.bind(this, ORDER_STATUS.canceled.value)
          }
        },
        // En proceso --> Enviado o cancelado (pedido para llevar)
        [ORDER_STATUS.inProcess.value]: {
          accept: {
            label: ORDER_STATUS.sent.label,
            fnAction: this.handleChangeStatus.bind(this, ORDER_STATUS.sent.value)
          },
          cancel: {
            label: ORDER_STATUS.canceled.label,
            fnAction: this.handleRejectForm.bind(this, ORDER_STATUS.canceled.value)
          }
        },
        // Enviado --> Entregado o cancelado (pedido para llevar)
        [ORDER_STATUS.sent.value]: {
          accept: {
            label: ORDER_STATUS.delivered.label,
            fnAction: this.handleChangeStatus.bind(this, ORDER_STATUS.delivered.value)
          },
          cancel: {
            label: ORDER_STATUS.canceled.label,
            fnAction: this.handleRejectForm.bind(this, ORDER_STATUS.canceled.value)
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Acciones de los botones
     *
     * @return {object}
     */
    actionButtons() {
      // Fecha del pedido
      const orderTimestamp = this.order.orderTimestamp
        ? formatDate(this.order.orderTimestamp, 'DD/MM/YYYY')
        : formatDate(new Date(), 'DD/MM/YYYY')
      const isSameDate = orderTimestamp === formatDate(new Date(), 'DD/MM/YYYY')
      // Slot del pedido
      const deliverySlot = this.order.deliverySlot || null
      // Acciones del estado actual
      const statusActions =
        this.order.status === ORDER_STATUS.inProcess.value && !this.isDelivery
          ? this.statusActions[`${this.order.status}_ta`]
          : this.statusActions[this.order.status]

      // Si se trata de un pre-pedido y nos encontramos en el estado de "aceptar", no debemos permitir continuar avanzando en los estados, hasta encontrarnos en el día del pedido
      if (this.order.status === ORDER_STATUS.accepted.value && deliverySlot && !isSameDate) {
        return { cancel: statusActions.cancel }
      }

      return statusActions
    },
    /**
     * Tiempo medio estimado de entrega
     *
     * @return {Number}
     */
    averageShippingTime() {
      return get(this.setupByUserTakeAway, 'averageShippingTime', 0)
    },
    /**
     * Posee el pedido un slot de fecha de pedido
     *
     * @return {boolean}
     */
    hasOrderDateSlot() {
      return Boolean(get(this.order, 'deliverySlot', false))
    },
    /**
     * ¿Es un pedido de tipo delivery?
     *
     * @return {Boolean}
     */
    isDelivery() {
      const orderType = get(this.order, 'type', null)
      return orderType === ORDER_TYPES.delivery.value
    },
    /**
     * Opciones seleccionadas por el usuario en el takeAway
     *
     * @return {Object}
     */
    setupByUserTakeAway() {
      return get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})
    },
    /**
     * Mostramos opciones de tiempo estimado
     *
     * @return {boolean}
     */
    showDeliveryTimeForm() {
      return this.order.status === ORDER_STATUS.pending.value && !this.hasOrderDateSlot
    }
  },
  watch: {
    averageShippingTime: {
      handler(value) {
        this.currentShippingTime = value
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Pulsamos sobre el botón "aceptar" del formulario de rechazo
     */
    async handleAcceptRejectForm() {
      await this.handleChangeStatus(this.currentStatus)
    },
    /**
     * Pulsamos sobre el botón "cancelar" del formulario de rechazo
     */
    handleCancelRejectForm() {
      this.reasonRejectionForm = false
    },
    /**
     * Botón de acción que muestra campo para rellenar
     * el "motivo del rechazo" del pedido
     *
     * @param {string} value - estado al que vamos a modificar
     */
    handleRejectForm(value) {
      // Mostramos formulario
      this.reasonRejectionForm = true
      // Modificamos el estado del pedido
      this.currentStatus = value
    },
    /**
     * Botón de acción para cambiar el estado del pedido
     *
     * @param {String} value - estado que deseamos modificar
     */
    async handleChangeStatus(value) {
      if (!isNil(value)) {
        this.currentStatus = value
      }

      // Loading processing
      this.processingRequest = true

      try {
        // Datos a salvar
        const dataToSave = { id: this.order.id, status: this.currentStatus }

        // Tiempo de entrega
        if (this.currentStatus === ORDER_STATUS.accepted.value && !this.hasOrderDateSlot) {
          dataToSave.shippingTime = this.currentShippingTime
        }

        // Motivo de rechazo (Rechazamos)
        if (
          this.currentStatus === ORDER_STATUS.rejected.value ||
          this.currentStatus === ORDER_STATUS.canceled.value
        ) {
          dataToSave.reasonRejection = this.reasonRejection || DEFAULT_REASON_REJECTION
        }

        // Actualizamos pedidos
        await updateOrderById(dataToSave)

        // Todo OK
        this.modifyAppAlert({
          text: 'Estado de pedido modificado correctamente',
          visible: true
        })
        // Emitimos el cambio de estado
        this.$emit('changeStatus', value)

        // Ocultamos formulario
        this.reasonRejectionForm = false
      } catch (error) {
        // Error en el proceso
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
