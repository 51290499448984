// Constants
import { ADDONS } from '@/constants'
import { ORDER_STATUS, ORDER_TYPES, DISTANCE_UNIT_DELIVERY_AREAS } from '../../constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import CurrencyPosition from '@/components/ui/CurrencyPosition'
import TakeAwayOrderProductsList from '../../components/elements/orders/TakeAwayOrderProductsList'
import TakeAwayOrderChangeDate from '../../components/elements/orders/TakeAwayOrderChangeDate'
import TakeAwayOrderChangeStatus from '../../components/elements/orders/TakeAwayOrderChangeStatus'
import TakeAwayDeliveryRadiusMap from '../../components/elements/orders/TakeAwayDeliveryRadiusMap'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
import VuetifyList from '@/components/ui/VuetifyList'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import printMixin from '@/mixins/printMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import { getOrderById } from '../../services/order'
// Filters
import { humanizePlaceObject, formatDate } from '@/filters'
import {
  orderStatusLabel,
  orderPaymentLabel,
  orderTypesLabel,
  orderStatusColor
} from '../../filters'
// Utils
import { get, isNil, cloneDeep } from 'lodash'
import { getCurrentBrand } from '@/utils'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'TakeAwayOrderDetail',
  components: {
    CardContainer,
    CurrencyPosition,
    TakeAwayOrderProductsList,
    TakeAwayOrderChangeDate,
    TakeAwayOrderChangeStatus,
    TakeAwayDeliveryRadiusMap,
    VuetifyContentLoading,
    VuetifyList,
    VuetifyToolBar
  },
  filters: {
    formatDate,
    humanizePlaceObject,
    orderStatusLabel,
    orderPaymentLabel,
    orderTypesLabel,
    orderStatusColor
  },
  mixins: [addonsMixin, printMixin, uiMixin],
  data() {
    return {
      id: this.$route.params.id || null,
      order: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * Areas del sistema de "delivery"
     *
     * @return {Array | Null}
     */
    deliveryAreas() {
      const deliveryAreas = get(this.setupByUserTakeAway, 'deliveryAreas', [])

      return cloneDeep(deliveryAreas).map((area) => {
        return {
          radius: area.distanceRadius
        }
      })
    },
    /**
     * Areas del sistema de "delivery"
     *
     * @return {Array | Null}
     */
    deliveryAreasOptions() {
      return {
        steps: 40,
        units: get(
          this.setupByUserTakeAway,
          'distanceUnitDeliveryAreas',
          DISTANCE_UNIT_DELIVERY_AREAS.kilometers.value
        )
      }
    },
    /**
     * Mostramos el componente de elección de slots (intervalo)
     *
     * @return {boolean}
     */
    showOrderDateSlotForm() {
      const hasDeliverySlot = Boolean(get(this.order, 'deliverySlot', false))
      const statusOrder = get(this.order, 'status', null)
      return (
        hasDeliverySlot &&
        (statusOrder === ORDER_STATUS.pending.value || statusOrder === ORDER_STATUS.accepted.value)
      )
    },
    /**
     * Logo del establecimiento
     *
     * @return {String}
     */
    placeLogo() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.place}.logo`, null)
    },
    /**
     * Coordenadas del pedido
     *
     * @return {Array | Null}
     */
    orderCoordinates() {
      const lon = get(this.order, 'address.longitude', null)
      const lat = get(this.order, 'address.latitude', null)

      return !isNil(lon) && !isNil(lat) ? [lon, lat] : null
    },
    /**
     * Coordenadas del establecimiento
     *
     * @return {Array | Null}
     */
    placeCoordinates() {
      const placeContactData = get(this.placeAddonsSetupByUser, `${ADDONS.contact}.place`, {})
      const lon = placeContactData.longitude || get(this.placeData, 'place.longitude', null)
      const lat = placeContactData.latitude || get(this.placeData, 'place.latitude', null)

      return !isNil(lon) && !isNil(lat) ? [lon, lat] : null
    },
    /**
     * ¿Es un pedido de tipo delivery?
     *
     * @return {Boolean}
     */
    isDelivery() {
      const orderType = get(this.order, 'type', null)
      return orderType === ORDER_TYPES.delivery.value
    },
    /**
     * ¿Esta dentro del area de reparto?
     *
     * @return {Boolean}
     */
    isInboundDeliveryArea() {
      return get(this.order, 'inboundDeliveryArea', true)
    },
    /**
     * Opciones elegidas por el usuario
     * dentro del "addons" "premiumMenu"
     *
     * @return {Object}
     */
    setupByUserPremiumMenu() {
      return get(this.placeAddonsSetupByUser, ADDONS.premiumMenu, {})
    },
    /**
     * Opciones elegidas por el usuario
     * dentro del "addons" "takeAway"
     *
     * @return {Object}
     */
    setupByUserTakeAway() {
      return get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})
    },
    /**
     * ¿Se muestra el mapa con el destino del pedido?
     *
     * @return {Boolean}
     */
    showDeliveryMap() {
      return (
        this.isDelivery &&
        this.orderCoordinates &&
        this.placeCoordinates &&
        getCurrentBrand(this.placeData.brand) !== 'schweppes'
      )
    },
    /**
     * Subtotal del precio de los productos
     *
     * @return {Number}
     */
    subTotalPrice() {
      const orderProducts = get(this.order, 'products', [])
      return orderProducts.length > 0
        ? this.order.products.reduce((sumProducts, product) => {
            sumProducts += product.quantity * product.price
            return sumProducts
          }, 0)
        : 0
    },
    /**
     * Total del pedido
     *
     * @return {Number}
     */
    totalPrice() {
      const orderServiceFee = this.order.serviceFee
      const orderShippingCost = this.order.shippingCost
      return this.subTotalPrice + orderServiceFee + orderShippingCost
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Evento que recoge el cambio de estado
     *
     * @param {string} status
     */
    handleChangeStatus(status) {
      this.order.status = status
    },
    /**
     * Evento que recoge el cambio de fecha
     *
     * @param {object} data
     */
    handleChangeDate(data) {
      this.order = { ...this.order, ...data }
    },
    /**
     * Cuando pulsamos en el botón de "Imprimir"
     */
    handleClickPrint() {
      this.printElement('.order-detail-ticket')
    },
    /**
     * Obtenemos/establecemos todos los datos necesarios del componente
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        // Obtenemos los clientes del establecimiento
        this.order = await getOrderById(this.id)
      } catch (error) {
        this.modifyAppAlert({
          type: 'error',
          text: error.message,
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
