// Contants
import { THEME_SECONDARY_COLOR } from '@/constants'
// Componentes
import VueMapBox from '@/components/ui/VueMapBox'
// Turf
import circle from '@turf/circle'
// Mapbox
import { MglMarker, MglNavigationControl, MglGeojsonLayer } from 'vue-mapbox'
// Utils
import { cloneDeep, orderBy } from 'lodash'

export default {
  name: 'TakeAwayDeliveryRadiusMap',
  components: {
    VueMapBox,
    MglMarker,
    MglNavigationControl,
    MglGeojsonLayer
  },
  props: {
    areas: {
      type: Array,
      default() {
        return []
      }
    },
    areaOptions: {
      type: Object,
      default() {
        return {
          steps: 40,
          units: 'kilometers'
        }
      }
    },
    sourcePoint: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    targetPoint: {
      type: Array,
      required: true,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      // Colores marcas
      sourceColor: THEME_SECONDARY_COLOR,
      targetColor: 'rgb(24, 40, 59)',
      // Opciones del mapa
      mapOptions: {
        center: this.sourcePoint,
        zoom: 13
      },
      // Others
      scaleColors: ['#1B5E20', '#2E7D32', '#FF6D00', '#FF9100', '#B71C1C', '#D32F2F']
    }
  },
  computed: {
    /**
     * Capa de areas
     *
     * @return {Array} - distintas areas (círculos a dibujar)
     */
    areaLayers() {
      return orderBy(cloneDeep(this.areas), ['radius'], ['asc']).map((area, index) => {
        return {
          // Datos de los puntos geográficos que conforman los polígonos
          source: {
            data: circle(this.sourcePoint, area.radius, this.areaOptions)
          },
          sourceId: `area-source-${index}`,
          // Capas de estilos
          layer: {
            id: `area-layer-${index}`,
            type: 'fill',
            source: `area-source-${index}`,
            paint: {
              'fill-color':
                index >= this.scaleColors.length - 1
                  ? this.scaleColors.length - 1
                  : this.scaleColors[index],
              'fill-opacity': 0.15
            }
          }
        }
      })
    }
  }
}
