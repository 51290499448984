// Components
import CurrencyPosition from '@/components/ui/CurrencyPosition'

export default {
  name: 'TakeAwayOrderProductsList',
  components: { CurrencyPosition },
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  computed: {
    /**
     * Conjuntamos raciones y modificadores
     *
     * @return {array}
     */
    itemModifiers() {
      // Comenzamos por el tipo de ración
      const rations =
        this.item.rationId == 0
          ? []
          : [
              {
                label: 'Ración',
                value: this.item.rationName
              }
            ]

      // Incluimos los modificadores
      return this.item.modifiers
        ? this.item.modifiers.reduce((sumModifiers, modifier) => {
            sumModifiers.push({
              label: modifier.name,
              value: modifier.childs.map((child) => child.name).join()
            })
            return sumModifiers
          }, rations)
        : rations
    }
  }
}
