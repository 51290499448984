// Constantes
import { ADDONS } from '@/constants'
import { ORDER_STATUS } from '../../../../constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import addonMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import { updateOrderById } from '../../../../services/order'
import { getDeliverySlotsByPlaceIdAndDate } from '@/services/place'
// Utils
import { get, isNil, cloneDeep } from 'lodash'
// filters
import { formatDate } from '@/filters'
// Vuex
import { mapGetters } from 'vuex'

export default {
  name: 'TakeAwayOrderChangeDate',
  components: { FormButtons },
  mixins: [addonMixin, formMixin, uiMixin, validationMixin],
  props: {
    order: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        orderTimestamp: null,
        deliverySlot: null
      },
      formFieldsValidations: {
        orderTimestamp: {
          required: 'Debes seleccionar una fecha'
        },
        deliverySlot: {
          required: 'Debes seleccionar una hora'
        }
      },
      // Fecha de entrega
      orderDate: null,
      orderDateMenu: false,
      orderSlotsData: []
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    /**
     * We need a computed option to pass an object with the differents
     * options about the vuetify buttons:
     *
     * https://vuetifyjs.com/en/components/buttons/#buttons
     *
     * @return {Object} - opciones vuetify
     */
    acceptButtonVOptions() {
      return {
        block: true
      }
    },
    /**
     * Fechas formateadas para el datepicker
     *
     * @return {string}
     */
    orderDateFormatted() {
      return !isNil(this.orderDate) ? `${formatDate(this.orderDate, 'DD/MM/YYYY')}` : null
    },
    /**
     * Opciones seleccionadas por el usuario en el takeAway
     *
     * @return {Object}
     */
    setupByUserTakeAway() {
      return get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})
    },
    /**
     * Mostramos opciones de "pre-pedidos" (fecha y hora)
     *
     * @return {boolean}
     */
    showDateTimeForm() {
      const orderStatus = get(this.order, 'status', null)
      return (
        !isNil(this.order.deliverySlot) &&
        (orderStatus === ORDER_STATUS.pending.value || orderStatus === ORDER_STATUS.accepted.value)
      )
    },
    /**
     * Opciones para el "v-date-picker"
     *
     * @return {object}
     */
    vDatePickerOptions() {
      // Horario de apertura
      const takeAwaySchedule = get(this.setupByUserTakeAway, 'takeAwaySchedule', [])
      // Días donde el servicio está cerrado
      const takeAwayScheduleClosedDays =
        takeAwaySchedule.length === 0
          ? [0, 1, 2, 3, 4, 5, 6]
          : takeAwaySchedule.reduce((sumDays, schedule) => {
              const fromH = get(schedule.data, '0.from.h', null)
              const fromM = get(schedule.data, '0.from.m', null)
              const toH = get(schedule.data, '0.to.h', null)
              const toM = get(schedule.data, '0.to.m', null)

              if (
                isNil(schedule.data) ||
                isNil(fromH) ||
                isNil(fromM) ||
                isNil(toH) ||
                isNil(toM)
              ) {
                sumDays.push(schedule.key)
              }

              return sumDays
            }, [])
      // Días máximos de pre-pedidos
      const preorderFutureMaxDays = get(this.setupByUserTakeAway, 'preorderFutureMaxDays', 7)
      // Días mínimos de pre-pedido
      const preoderMinDays = get(this.setupByUserTakeAway, 'preoderMinDays', 1)
      const today = new Date()
      const maxDate = new Date()
      const minDate = new Date()

      minDate.setDate(today.getDate() + preoderMinDays)
      maxDate.setDate(today.getDate() + preorderFutureMaxDays)

      return {
        allowedDates: (date) => {
          const currentDay = new Date(date).getDay()
          return takeAwayScheduleClosedDays.indexOf(currentDay) === -1
        },
        color: 'secondary',
        'first-day-of-week': 1,
        max: formatDate(maxDate, 'YYYY-MM-DD'),
        min: formatDate(minDate, 'YYYY-MM-DD'),
        'no-title': true,
        scrollable: true
      }
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  watch: {
    // Se modifica el día del pedido
    async orderDate(value) {
      // Eliminamos opciones anteriores
      this.orderSlotsData = []

      if (!isNil(value)) {
        const date = new Date(value)
        // Obtenemos slots del día indicado
        this.orderSlotsData = await this.getOrderSlots(date)
        // Modificamos el campo del formulario que almacenaremos
        this.formFields.orderTimestamp = date.getTime()
      }
    },
    // Se modifica la hora del pedido
    'formFields.deliverySlot'(value) {
      const slot = this.orderSlotsData.find((orderSlot) => {
        return orderSlot.value === value
      })

      if (!isNil(slot)) {
        this.formFields.orderTimestamp = slot.date
      }
    }
  },
  methods: {
    /**
     * Obtenemos/establecemos todo necesario para
     * inicializar el componente
     */
    getEveryNeededData() {
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      this.formFields = {
        orderTimestamp: this.order.orderTimestamp,
        deliverySlot: this.order.deliverySlot
      }
      // Parseamos el dato para que pueda ser usado en el "date-picker"
      this.orderDate = formatDate(this.formFields.orderTimestamp, 'YYYY-MM-DD', false)
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualizamos pedido
      await updateOrderById({
        id: this.order.id,
        ...this.formFields
      })

      // Todo OK
      this.modifyAppAlert({
        text: 'Fecha de pedido modifica correctamente',
        visible: true
      })

      // Emitimos el cambio de fecha
      this.$emit('changeDate', {
        ...this.formFields
      })
    },
    /**
     * Obtenemos los "slots" (intervalos) de una fecha
     *
     * @param {object} date - Date object
     * @return {array}
     */
    async getOrderSlots(date) {
      try {
        // Loading
        this.formProcessing = true

        // Hora local (fecha)
        const now = new Date()
        // Fecha para filtrar (empieza a las 00:00:00 h)
        const dateFilter = cloneDeep(date)

        // Incluimos minutos y segundos de la hora actual
        dateFilter.setHours(now.getHours(), now.getMinutes())

        // Obtenemos los intervalos (slots) disponibles
        const deliverySlots = await getDeliverySlotsByPlaceIdAndDate(
          this.placeData.id,
          dateFilter.getTime()
        )

        // Preparamos los intervalos de tiempo a mostrar
        return deliverySlots.map((deliverySlot) => {
          return {
            disabled: !deliverySlot.available,
            value: deliverySlot.id,
            date: deliverySlot.timestamp,
            label: formatDate(deliverySlot.timestamp, 'H:mm', false)
          }
        })
      } catch (error) {
        // Mostramos posible error
        this.setAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Loading
        this.formProcessing = false
      }
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      orderTimestamp: { required },
      deliverySlot: { required }
    }
  }
}
