<template>
  <div class="vuetify-list">
    <template v-if="!loading && items.length > 0">
      <v-list class="items">
        <div v-for="(item, index) in items" :key="index">
          <slot :item="item"></slot>
          <v-divider v-if="index !== items.length - 1"></v-divider>
        </div>
      </v-list>
    </template>
    <template v-if="!loading && items.length === 0">
      <div class="empty grey lighten-4">{{ labelNoItems }}</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VuetifyList',
  props: {
    labelNoItems: {
      type: String,
      default() {
        return 'No existen registros'
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.vuetify-list {
  height: auto;
  width: 100%;
  .v-list {
    padding: 0;
    background: transparent;
  }
  .empty {
    padding: 0.5rem;
    font-size: 0.85rem;
    line-height: 0.85rem;
    text-align: center;
  }
}
</style>
