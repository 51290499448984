<template>
  <div class="vue-map-box">
    <MglMap :access-token="accessToken" :map-style="mapStyle" v-bind="mBoxOptions">
      <!-- Content -->
      <slot></slot>
    </MglMap>
  </div>
</template>

<script>
// Mapbox
import Mapbox from 'mapbox-gl'
import { MglMap } from 'vue-mapbox'
// Mapbox - CSS
import 'mapbox-gl/dist/mapbox-gl.css'

// MAP BOX
const MP_TOKEN =
  'pk.eyJ1IjoiYWlybGFuZGVzIiwiYSI6ImNraHZ6amo4MTBrY2gzMW8ydm1xYjYzN28ifQ.hYVTCXXN-phownA-cwOiIQ'
const MP_STYLE = 'mapbox://styles/airlandes/ckhw0a6pe0w8b19nzsdq835ql'

export default {
  name: 'VueMapBox',
  components: {
    MglMap
  },
  props: {
    // Opciones del componente de mapa
    // https://soal.github.io/vue-mapbox/api/#props
    mBoxOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      accessToken: MP_TOKEN,
      mapStyle: MP_STYLE
    }
  },
  created() {
    // Obtenemos y seteamos todo lo necesario para inicializar el componente
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Inicializamos y seteamos todo lo necesario para el componente
     */
    getEveryNeededData() {
      this.mapbox = Mapbox
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-map-box {
  height: 100%;
  width: 100%;
}
</style>
